#loader__triangle {
  width: 100%;
  height: 100%;
  min-height: 65vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .loader-triangle-3 {
    #triangle {
      width: 200px;
      height: 200px;
      @media only screen and (max-width: 600px) {
        width: 100px;
        height: 100px;
      }
    }
    polygon {
      fill: transparent;
      stroke-width: 2;
      stroke: rgb(0 0 0 / 15%);
      stroke-dasharray: 17;
      stroke-linecap: round;
      animation: anm-tr-3-move 2.5s cubic-bezier(0.35, 0.04, 0.63, 0.95) infinite;
    }
  }

  @keyframes anm-tr-3-move {
    to {
      stroke-dashoffset: -136;
    }
  }
}
